import React from "react";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Seo from "../components/Seo/Seo";
import StripHTML from "../utils/StripHTML";
import GImage from "../components/Picture/GImage";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Publications from "../components/Publications/Publications";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import { graphql, Link } from "gatsby";
import Contact from "../components/Contact/Contact";

const PublicationTemplate = (props) => {
  const { directus } = props.data;
  const slugify = require("slugify");

  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {/* {directus.settings &&
        directus.settings.publications_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-publications bg-cover"
            src={directus.settings.publications_illustration.imageFile}
            alt="Image de la page d'une publication du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )} */}
      <div className="publications-container min-h-80vh">
        <Seo
          title={directus.publications_by_id.title}
          description={`${directus.publications_by_id.title} : ${
            directus.publications_by_id.intro &&
            directus.publications_by_id.intro.length > 1
              ? StripHTML(directus.publications_by_id.intro)
              : directus.publications_by_id.description &&
                directus.publications_by_id.description.length > 1
              ? StripHTML(directus.publications_by_id.description)
              : "publication du " + config.siteTitleShort
          }`}
          article={true}
        />
        <Breadcrumb location={"publications"} />
        {directus.publications_by_id && (
          <div className="publications pt-8 pb-4 flex items-center">
            <div className="relative w-full">
              <div className="max-w-6xl mx-auto px-3 sm:px-6">
                <h1 className="text-center mt-6 mb-8">
                  {directus.publications_by_id.title}
                </h1>
                <hr
                  className={`border-none h-2 ${
                    directus.publications_by_id.type === "Livre"
                      ? "bg-red-500"
                      : "bg-gray-700"
                  }`}
                />
                {directus.publications_by_id.intro && (
                  <div className="text-center max-w-3xl mx-auto mt-6 mb-12">
                    <p className="text-lg text-gray-600">
                      {directus.publications_by_id.intro}
                    </p>
                  </div>
                )}
              </div>
              <div className="w-full relative">
                <div className="pb-8 max-w-6xl mx-auto px-3 sm:px-6">
                  {directus.publications_by_id.illustration && (
                    <div className="w-full float-none lg:float-left lg:w-1/2 pr-0 pb-6 lg:pr-6">
                      <GImage
                        objectFit="contain"
                        imgClassName="h-auto w-full max-h-128 min-w-0 min-h-0"
                        className="h-auto w-full max-h-128 min-w-0 min-h-0"
                        src={directus.publications_by_id.illustration.imageFile}
                        alt="Illustration de la publication du secteur langues du Groupe Français d'éducation Nouvelle"
                      />
                      {directus.publications_by_id.illustration.description && (
                        <p className="text-sm italic text-gray-600 mt-2 text-center">
                          {directus.publications_by_id.illustration.description}
                        </p>
                      )}
                    </div>
                  )}
                  {directus.publications_by_id.intro && (
                    <div
                      className="mt-6 text-editor"
                      dangerouslySetInnerHTML={{
                        __html: `${directus.publications_by_id.intro}`,
                      }}
                    />
                  )}
                  {directus.publications_by_id.description && (
                    <div
                      className="mt-6 text-editor"
                      dangerouslySetInnerHTML={{
                        __html: `${directus.publications_by_id.description}`,
                      }}
                    />
                  )}
                  {directus.publications_by_id.documents.length > 0 && (
                    <div className="w-full clear-both">
                      <h4 className="flex items-center mt-10 mb-1">
                        <span className={`material-icons-outlined pr-2 py-0`}>
                          description
                        </span>
                        Document
                        {directus.publications_by_id.documents.length > 1 &&
                          "s"}{" "}
                      </h4>
                      <ul>
                        {directus.publications_by_id.documents.map(
                          (document) => {
                            return (
                              document &&
                              document.directus_files_id !== null && (
                                <li
                                  className={`my-2 px-3 bg-white mb-3 shadow-md ${
                                    document.directus_files_id.description
                                      ? "py-2"
                                      : "py-2"
                                  }`}
                                  key={document.directus_files_id.id}
                                >
                                  <div className="inline-flex items-center">
                                    <a
                                      title="Télécharger le document"
                                      className={`inline-flex items-center hover:bg-gray-100 px-2 py-2`}
                                      target="_blank"
                                      rel="noreferrer"
                                      href={
                                        document.directus_files_id.imageFile
                                          .publicURL
                                      }
                                    >
                                      <span
                                        className={`material-icons-outlined hover:bg-gray-100 ${
                                          directus.publications_by_id.type ===
                                          "Livre"
                                            ? "text-red-500"
                                            : "text-gray-700"
                                        }`}
                                      >
                                        file_download
                                      </span>
                                    </a>
                                    {document.directus_files_id.imageFile
                                      .publicURL && (
                                      <PdfViewer
                                        title={document.directus_files_id.title}
                                        extension={
                                          document.directus_files_id.imageFile
                                            .extension
                                        }
                                        textClass={
                                          directus.publications_by_id.type ===
                                          "Livre"
                                            ? "text-red-500"
                                            : "text-gray-700"
                                        }
                                        pdfFile={
                                          document.directus_files_id.imageFile
                                            .publicURL
                                        }
                                      />
                                    )}
                                    <span className="ml-2 font-normal text-base">
                                      {document.directus_files_id.title}
                                    </span>
                                  </div>

                                  {document.directus_files_id.description && (
                                    <p className="pb-2 px-2">
                                      <i className="text-gray-600 text-sm">
                                        {document.directus_files_id.description}
                                      </i>
                                    </p>
                                  )}
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="bg-gray-300 pt-12 pb-20 flex justify-center">
                  <div className="w-full relative max-w-6xl mx-auto px-3 sm:px-6">
                    <h4 className="mb-0 flex items-center text-gray-700">
                      <span className={`material-icons-outlined  pr-2 py-0`}>
                        email
                      </span>
                      Contact
                    </h4>
                    <Contact defaultSubject="commande" background={false} />
                  </div>
                </div>
                {directus.publications_by_id.associated_practices &&
                  directus.publications_by_id.associated_practices.length >=
                    1 && (
                    <div className="pratiques-relations  max-w-6xl mx-auto px-3 sm:px-6 mt-10 w-full clear-both">
                      <h4>
                        Pratique
                        {directus.publications_by_id.associated_practices &&
                        directus.publications_by_id.associated_practices
                          .length > 1
                          ? "s"
                          : ""}{" "}
                        en relation
                      </h4>
                      <ul>
                        {directus.publications_by_id.associated_practices.map(
                          (practice) => {
                            return (
                              <li
                                key={practice.practices_id.id}
                                className="my-2 px-3 py-4 bg-white mb-3 shadow-md"
                              >
                                <Link
                                  className="flex items-center"
                                  to={`/pratiques/${slugify(
                                    practice.practices_id.categories[0]
                                      .categories_id.title,
                                    {
                                      lower: true,
                                      strict: true,
                                    }
                                  )}#${slugify(practice.practices_id.title, {
                                    lower: true,
                                    strict: true,
                                  })}`}
                                >
                                  <span
                                    className={`text-gray-600 p-0 m-0 -ml-2 text-left material-icons-outlined`}
                                  >
                                    arrow_right
                                  </span>
                                  {practice.practices_id.title}
                                </Link>
                                <p className="mt-1">
                                  <i className="text-gray-600 text-sm">
                                    {`Rubrique : ${practice.practices_id.categories[0].categories_id.title}`}
                                  </i>
                                </p>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
      {directus.publications && directus.keywords && (
        <Publications
          title={`Autres publications`}
          subpage={true}
          subtitle1="Rechercher par mot-clé"
          subtitle2={`${props.pageContext.type}s`}
          publications={directus.publications}
          keywords={directus.keywords}
        />
      )}
    </Layout>
  );
};
export default PublicationTemplate;

export const query = graphql`
  query ($id: ID!, $title: String!, $type: String!) {
    directus: allDirectus {
      # settings {
      #   id
      #   publications_illustration {
      #     id
      #     imageFile {
      #       id
      #       childImageSharp {
      #         id
      #         gatsbyImageData(
      #           quality: 80
      #           layout: FULL_WIDTH
      #           placeholder: TRACED_SVG
      #           tracedSVGOptions: { color: "#d8b0b1" }
      #         )
      #       }
      #     }
      #   }
      # }
      keywords(filter: { publications: { id: { _nnull: true } } }) {
        id
        name
      }
      publications(
        sort: "sort"
        filter: { title: { _neq: $title }, type: { _eq: $type } }
      ) {
        id
        title
        type
        intro
        description
        keywords {
          keywords_id {
            name
          }
        }
      }
      publications_by_id(id: $id) {
        id
        title
        type
        intro
        description
        illustration {
          id
          description
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
        keywords {
          keywords_id {
            name
          }
        }
        documents {
          directus_files_id {
            id
            title
            filename_disk
            filename_download
            description
            imageFile {
              id
              extension
              publicURL
            }
          }
        }
        associated_practices {
          id
          practices_id {
            id
            categories {
              id
              categories_id {
                title
              }
            }
            title
          }
          publications_id {
            id
            title
          }
        }
      }
    }
  }
`;
